<template>
  <a @click="openTermOfUse">Hinweise und Nutzungsbedingen - {{ title }}</a>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  methods: {
    openTermOfUse() {
      this.$emit('openTermOfUse', {});
    }
  }
};
</script>

<style lang="scss" scoped>
.copyright {
  cursor: pointer;
}
</style>
